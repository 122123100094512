import dynamicRoutes from "@dynamicRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { UserStatus } from "../api/models/user";
import { useAuthStore } from "../store/auth";
import { useUserStore } from "../store/user";

const sharedRoutes: RouteRecordRaw[] = [
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("../components/FormLogin.vue"),
      },
      {
        path: "/redefinicao-de-senha",
        name: "Redefinição senha",
        component: () => import("../components/FormRequestPasswordReset.vue"),
      },
      {
        path: "/redefinir-senha",
        name: "Redefinir senha",
        component: () => import("../components/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Não encontrada",
    component: () => import("../views/Error404.vue"),
  },
  {
    path: "/navbar",
    name: "NavBar",
    component: () => import("../views/user/navbar.vue"),
  },
  {
    path: "/categories",
    name: "Categorias",
    component: () => import("../views/user/Categories.vue"),
    children: [
      {
        path: "notebooks",
        name: "Notebooks",
        component: () => import("../views/user/categories/Notebooks.vue"),
      },
    ],
  },
  {
    path: "/resources",
    name: "Recursos",
    component: () => import("../views/user/Resources.vue"),
    children: [
      {
        path: "acer",
        name: "Acer",
        component: () => import("../views/user/resources/Acer.vue"),
      },
      {
        path: "partners",
        name: "Parceiros",
        component: () => import("../views/user/resources/Parceiros.vue"),
      },
    ],
  },
];

const routes = [...sharedRoutes, ...dynamicRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const navbarHeight = document.getElementById("navbar")?.clientHeight ?? 0;

      return {
        el: to.hash,
        top: navbarHeight,
        behavior: "smooth",
      };
    }

    return { top: 0 };
  },
});

function generateFlatRoutes(rawRoutes: RouteRecordRaw[], parentPath?: string) {
  const _flatRoutes: RouteRecordRaw[] = [];

  rawRoutes.forEach((route) => {
    if (route.children) {
      _flatRoutes.push(...generateFlatRoutes(route.children, route.path));
    } else {
      if (parentPath) {
        route.path = (parentPath + "/" + route.path).replace(new RegExp("/{2,}", "gm"), "/");
      }
      _flatRoutes.push(route);
    }
  });

  return _flatRoutes;
}

export const flatRoutes = generateFlatRoutes(routes);

router.beforeEach(async (to) => {
  document.title = to.name ? `Parceiros Acer | ${to.name.toString()}` : "Parceiros Acer";

  if (to.name === "Não encontrada") {
    return;
  }

  const authStore = useAuthStore();
  const userStore = useUserStore();

  if (to.meta.requiresAuth && !authStore.isUserLoggedIn) {
    return "/login";
  }

  await userStore.loadUserData();

  const userStatus = userStore.activeUser?.status;
  const isUserStatusUnconfirmedEmailOrFirstAccess =
    userStatus && [UserStatus.UNCONFIRMED_EMAIL, UserStatus.FIRST_ACCESS].includes(userStatus);
  const hasPermissionController =
    to.meta.permissionController &&
    userStore.doesActiveUserHavePermissionController(to.meta.permissionController);
  const shouldRedirectToSignUpSteps =
    to.path !== "/cadastro" || (to.path === "/cadastro" && !to.query.step);
  const pathToSignUpSteps = `/cadastro?step=${
    userStore.activeUser?.status === UserStatus.UNCONFIRMED_EMAIL ? 2 : 3
  }`;

  if (
    (!to.meta.requiresAuth && authStore.isUserLoggedIn) ||
    (to.meta.permissionController && !hasPermissionController)
  ) {
    if (isUserStatusUnconfirmedEmailOrFirstAccess) {
      // Envia o usuário para a tela de cadastro para completar o processo
      if (shouldRedirectToSignUpSteps) {
        return pathToSignUpSteps;
      }
    } else {
      return userStore.initialLoggedInRoute;
    }
  } else if (isUserStatusUnconfirmedEmailOrFirstAccess) {
    if (shouldRedirectToSignUpSteps) {
      return pathToSignUpSteps;
    }
  }
});

export default router;
