import { setupWorker, rest } from "msw";

import { IProduct } from "../api/models/product";
import { joinPaths } from "../utils";

const baseURL = import.meta.env.VITE_API_BASE_URL;

let cards = [
  { id: "1", label: "Cartão visa", brand: "Visa", last4CardNumber: "4241" },
  { id: "2", label: "Cartão master", brand: "MasterCard", last4CardNumber: "4242" },
  { id: "3", label: "Cartão black", brand: "Visa", last4CardNumber: "4243" },
  { id: "4", label: "Cartão silver", brand: "Visa", last4CardNumber: "4244" },
];

const banners = [
  {
    image: "https://picsum.photos/id/1/516/172",
    text: "Imagem teste 1, com texto bem grande pra ver como fica na tela, lorem ipsum lorem ipsum.",
  },
  { image: "https://picsum.photos/id/2/516/172", text: "Imagem teste 2" },
];

const category = {
  id: "1",
  name: "Notebooks",
};

const products: { data: IProduct[] } = {
  data: [
    {
      id: "1",
      code: "A315-510P-320B",
      family: { id: "1", name: "Aspire-3", category },
      ean13: "123456789012",
      partNumber: "123456789012",
      releaseDate: new Date(),
      lastUpdate: new Date(),
      imageUrl:
        "https://parceirosacer.com.br/Conteudos-Especiais/Notebooks/Aspire-3/A315-510P-320B/A315-510P-320B.jpg",
      category,
    },
    {
      id: "2",
      code: "A514-54-57HC",
      family: { id: "2", name: "Aspire-5", category },
      ean13: "123456789012",
      partNumber: "123456789012",
      releaseDate: new Date("2024-08-04"),
      lastUpdate: new Date("2024-08-04"),
      imageUrl:
        "https://parceirosacer.com.br/Conteudos-Especiais/Notebooks/Aspire-5/A514-54-57HC/A514-54-57HC.jpg",
      category,
    },
    {
      id: "3",
      code: "	SF14-11T-X0WB",
      family: { id: "1", name: "Swift AI", category },
      ean13: "123456789012",
      partNumber: "123456789012",
      releaseDate: new Date("2024-09-17"),
      lastUpdate: new Date("2024-09-17"),
      imageUrl:
        "https://parceirosacer.com.br/Conteudos-Especiais/Notebooks/Swift-AI/	SF14-11T-X0WB/SF14-11T-X0WB.jpg",
      category,
    },
  ],
};

const mocks = [
  rest.post<{
    name: string;
  }>(joinPaths(baseURL, "mockEndpoint"), (req, res, ctx) => {
    const { name } = req.body;

    if (name) {
      return res(ctx.status(201), ctx.json({ name }), ctx.delay(1000));
    }

    return res(ctx.status(400), ctx.json({ message: "Mensagem de erro" }));
  }),

  rest.get(joinPaths(baseURL, "app/credit_card"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(cards), ctx.delay(1000));
  }),

  rest.delete(joinPaths(baseURL, "app/credit_card/:cardId"), (req, res, ctx) => {
    const { cardId } = req.params;

    if (cardId === "3") {
      return res(ctx.status(400), ctx.json({ message: "Erro ao deletar o cartão." }));
    }

    cards = cards.filter((card) => card.id !== cardId);

    return res(ctx.status(200), ctx.delay(1000));
  }),

  rest.get(joinPaths(baseURL, "product"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(products), ctx.delay(1000));
  }),

  rest.get(joinPaths(baseURL, "product/banners"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(banners), ctx.delay(1000));
  }),
];

export default setupWorker(...mocks);
